
   $( document ).ready(function() { 
        setInterval(function () { 
                 if($('button#la-select-lenses-btn').length){
	$('.lenses_button_new').show();
}else{
	$('.lenses_button_new').hide();
}  
        $('.la-wrapper-heading h3').each(function() { 
          $(this).html($(this).html().replace('Choose Your Prescription Type','Τύπος Φακών:'));
          $(this).html($(this).html().replace('Add Your Prescription','Συνταγογράφηση')); 
          $(this).html($(this).html().replace('Upload Prescription','Ανέβασμα Συνταγογράφησης')); 
          $(this).html($(this).html().replace('Choose Your Lenses','Επιλογή Φακών')); 
          $(this).html($(this).html().replace('Please Review Your Order','Σύνοψη Παραγγελίας')); 
          $(this).html($(this).html().replace('Enter Your Prescription Manually','Συμπλήρωσε Τις Μετρήσεις Σου'));   
          });  
          
          $('.la-label-title').each(function() { 
          $(this).html($(this).html().replace('Upload File','<div class="option_1_container"><div class="container_left_"><p class="title_text__">Αρχείο Συνταγής</p><p class="bottom_text__">Ανέβασε φωτογραφία ή αρχείο της συνταγής από τον οφθαλμίατρο</p></div><div class="button_container_opt"><span>ΑΝΕΒΑΣΜΑ ΑΡΧΕΙΟΥ</span></div></div>')); 
          $(this).html($(this).html().replace('Enter Manually','<div class="option_1_container"><div class="container_left_"><p class="title_text__">Προσθήκη Συνταγής Χειροκίνητα</p><p class="bottom_text__">Καταχώρησε τις μετρήσεις σου χειροκίνητα</p></div><div class="button_container_opt"><span>ΣΥΜΠΛΗΡΩΣΗ</span></div></div>')); 
          $(this).html($(this).html().replace('Email Later','<div class="option_1_container"><div class="container_left_"><p class="title_text__">Στείλε την συνταγή αργότερα</p><p class="bottom_text__">Στείλε μας τη συνταγή σου με e-mail στο eyedocare.glasses@gmail.com</p></div><div class="button_container_opt"><span>ΟΛΟΚΛΗΡΩΣΗ</span></div></div>')); 
          $(this).html($(this).html().replace('Order Notes','Σημειώσεις'));  
          });

          $('button.la-prescription-form-btn').each(function() { 
          $(this).html($(this).html().replace('Save and Continue','ΕΠΟΜΕΝΟ')); 
          $(this).html($(this).html().replace('Add to Cart','ΕΠΙΛΟΓΗ'));  
          });
          
          $('h6.la-h6').each(function() {  
          $(this).html($(this).html().replace('OD (Right Eye)','Δ.Ο. (Δεξί Μάτι)'));
          $(this).html($(this).html().replace('OS (Left Eye)','Α.Ο. (Αριστερό Μάτι)'));  
          });

          $('p.la-information').each(function() { 
          $(this).html($(this).html().replace('Upload your prescription for us to confirm that you have entered it correctly (Optional).','Ανέβασε το αρχείο συνταγογράφησης για αποφυγή λαθών. (Δεν είναι υποχρεωτικό)'));
          $(this).html($(this).html().replace('Please upload your prescription as a PNG, JPG or PDF file.','Ανέβασε την συνταγογράφηση σου σε μορφή PNG,JPG ή PDF')); 
          });

           $('label.la-label-btn.la-prescription-file-label span').each(function() { 
          $(this).html($(this).html().replace('Choose File','Επιλογή Αρχείου')); 
           });
          
          
          
          
          // $('h3#la_prescriptionSelect_title').html($('h3#la_prescriptionSelect_title').html().replace('Choose Your Prescription Type','Τύπος Φακών:'));
          // $('h3#la_prescriptionSelect_title').html($('h3#la_prescriptionSelect_title').html().replace('Add Your Prescription','Συνταγογράφηση')); 
          // $('h3#la_prescriptionSelect_title').html($('h3#la_prescriptionSelect_title').html().replace('Upload Prescription','Ανέβασμα Συνταγογράφησης')); 
          // $('h3#la_prescriptionSelect_title').html($('h3#la_prescriptionSelect_title').html().replace('Choose Your Lenses','Επιλογή Φακών')); 
          // $('h3#la_prescriptionSelect_title').html($('h3#la_prescriptionSelect_title').html().replace('Please Review Your Order','Σύνοψη Παραγγελίας')); 
          // $('h3#la_prescriptionSelect_title').html($('h3#la_prescriptionSelect_title').html().replace('Enter Your Prescription Manually','Συμπλήρωσε Τις Μετρήσεις Σου')); 

         
          // $('.la-label-title').html($(this).html().replace('Upload File','Ανέβασμα Αρχείου')); 
          // $('.la-label-title').html($(this).html().replace('Enter Manually','Προσθήκη Χειροκίνητα')); 
          // $('.la-label-title').html($(this).html().replace('Email Later','Αποστολή Αργότερα')); 
          // $('.la-label-title').html($(this).html().replace('Order Notes','Σημειώσεις'));

            
          
          // $('label.la-label-btn.la-prescription-file-label span').html($('label.la-label-btn.la-prescription-file-label span').html().replace('Choose File','Επιλογή Αρχείου')); 
          
          // $('button.la-prescription-form-btn').html($('button.la-prescription-form-btn').html().replace('Save and Continue','ΕΠΟΜΕΝΟ')); 
          // $('button.la-prescription-form-btn').html($('button.la-prescription-form-btn').html().replace('Add to Cart','ΕΠΙΛΟΓΗ')); 
            
          // $('h6.la-h6').html($('h6.la-h6').html().replace('OD (Right Eye)','Δ.Ο. (Δεξί Μάτι)'));
          // $('h6.la-h6').html($('h6.la-h6').html().replace('OS (Left Eye)','Α.Ο. (Αριστερό Μάτι)')); 
          
          // $('p.la-information.la-text-left').html($('p.la-information.la-text-left').html().replace('Upload your prescription for us to confirm that you have entered it correctly (Optional).','Ανέβασε το αρχείο συνταγογράφησης για αποφυγή λαθών. (Δεν είναι υποχρεωτικό)'));
          // $('p.la-information').html($('p.la-information').html().replace('Please upload your prescription as a PNG, JPG or PDF file.','Ανέβασε την συνταγογράφηση σου σε μορφή PNG,JPG ή PDF')); 
          
          
        }, 1);
        
});
      
  $(document).ready(function() {
// Swiper: Slider
    new Swiper('.swiper-container1', {
        autoplay: {
   delay: 3500,
 },
        loop: true,
        slidesPerView: 1,
        paginationClickable: true,
        spaceBetween: 20,
        breakpoints: {
            1920: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            1028: {
                slidesPerView: 1,
                spaceBetween: 30
            },
            480: {
                slidesPerView: 1,
                spaceBetween: 10
            }
        }
    });
});
 